import './Footer.css'
import {motion, useScroll, useSpring} from "framer-motion";


function Footer() {
    const {scrollYProgress} = useScroll();
    const scaleX = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    return (
        <>
        <motion.div className="progress-bar" style={{scaleX}} />
        <div style={{width: "100%", height: "4em"}} />
        <div className='Footer'>
            <p> Copyright © 2023, Karol Czopek - All rights reserved </p>
        </div>
        </>
    );
}

export default Footer;
