import {useInView} from "framer-motion"
import {useEffect, useRef} from "react"
import TimeLineElement from "../TimeLineElement"

function Education({setPagesInView}) {
    const ref = useRef(null)
    const isInView = useInView(ref)
    useEffect(() => {
        setPagesInView(prevPagesInView => {
            let newPagesInView = [...prevPagesInView];
            newPagesInView[4] = isInView;
            return newPagesInView;
        });
    }, [isInView, setPagesInView])
    return (
        <div className="PageContainer">
            <div ref={ref} style={{display: "flex", flexFlow: "column wrap"}}>
                <TimeLineElement
                    time="10.2016 – 12.2021"
                    role="Bachelor’s Degree in Computer Science"
                    location="Humboldt University of Berlin"
                    description="The university helped me gain a solid foundation in computer science. Not only in theoretical fields, but also in programming, communication systems, the inner workings of operating systems and a lot more."
                />
                <TimeLineElement
                    time="09.2018 – 09.2019"
                    role="Exchange Year"
                    location="Waseda University of Tokyo"
                    description="At Waseda I have taken Japanese language courses, but also learned a lot about machine learning related subjects and image processing systems."
                />
                <TimeLineElement
                    time="07.2016"
                    role="European Baccalaureate"
                    location="Katholisches Gymnasium St. Marien Berlin"
                    description="Here I was first exposed to computer science. I was also able to study my native language Polish in writing form and engage in social activities by beeing a member of a choir."
                />
            </div>
        </div >
    );
}

export default Education;

