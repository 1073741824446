import {useState, useEffect, useRef} from 'react';
import './App.css';
import Header from './headerFooter/Header';
import Footer from './headerFooter/Footer';

import Home from './content/Home';
import About from './content/About';
import Portfolio from './content/Portfolio';
import Education from './content/Education';
import Experience from './content/Experience';
import Contact from './content/Contact';

function App() {
    let items = ["Home", "About", "Portfolio", "Experience", "Education", "Contact"];
    let [pagesInView, setPagesInView] = useState(new Array(items.length).fill(false));


    //This part is used when the nav bar is clicked. It scrolls the site the clicked item.
    let [currentPage, setCurrentPage] = useState("Home");
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const portfolioRef = useRef(null);
    const educationRef = useRef(null);
    const experienceRef = useRef(null);
    const contactRef = useRef(null);
    const scrollToSection = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth'});
        }
    };
    useEffect(() => {
        if (currentPage) {
            switch (currentPage) {
                case "Home":
                    scrollToSection(homeRef);
                    break;
                case "About":
                    scrollToSection(aboutRef);
                    break;
                case "Portfolio":
                    scrollToSection(portfolioRef);
                    break;
                case "Experience":
                    scrollToSection(experienceRef);
                    break;
                case "Education":
                    scrollToSection(educationRef);
                    break;
                case "Contact":
                    scrollToSection(contactRef);
                    break;
                default:
                    break;
            }
            setCurrentPage(null);
        }
    }, [currentPage]);



    return (
        <div className="App">
            <Header items={items} pagesInView={pagesInView} setCurrentPage={setCurrentPage} setPagesInView={setPagesInView} />
            <div ref={homeRef} ><Home pagesInView={pagesInView} setPagesInView={setPagesInView} /></div>
            <div ref={aboutRef} ><About pagesInView={pagesInView} setPagesInView={setPagesInView} /></div>
            <div ref={portfolioRef} ><Portfolio pagesInView={pagesInView} setPagesInView={setPagesInView} /></div>
            <div ref={experienceRef} ><Experience pagesInView={pagesInView} setPagesInView={setPagesInView} /></div>
            <div ref={educationRef} ><Education pagesInView={pagesInView} setPagesInView={setPagesInView} /></div>
            <div ref={contactRef} ><Contact pagesInView={pagesInView} setPagesInView={setPagesInView} /></div>
            <Footer />
        </div>
    );
}

export default App;
