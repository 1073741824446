function TimeLineElement({time, role, location, description}) {
    return (
        <div style={{
            margin: "10px",
            marginTop: "15px",
            marginBottom: "15px",
            textAlign: "left",
            maxWidth: "800px",
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "flex-start"
        }}>
            <div style={{
                width: "150px",
            }} > {time} </div>
            <div style={{
                display: "flex",
                flexFlow: "column wrap",
                gap: "5px"
            }}>
                <div style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignContent: "flex-end",
                    fontWeight: "bold"
                }}>
                    <div> {role} </div>
                    <div> {location} </div>
                </div>
                <div style={{
                    maxWidth: "650px",
                    textAlign: "justify"
                }}>
                    {description}
                </div>
            </div>
        </div>
    );
}

export default TimeLineElement;
