import {useInView} from "framer-motion"
import {useEffect, useRef, useState} from "react"
import Tags from "./Tags"

function Portfolio({setPagesInView}) {
    const ref = useRef(null)
    const isInView = useInView(ref)
    useEffect(() => {
        setPagesInView(prevPagesInView => {
            let newPagesInView = [...prevPagesInView];
            newPagesInView[2] = isInView;
            return newPagesInView;
        });
    }, [isInView, setPagesInView])
    return (
        <div className="PageContainer">
            <div ref={ref} style={{display: "flex", flexFlow: "column wrap"}}>
                <div style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "center",
                    maxWidth: "1000px",
                }}>
                    <PortfolioItem
                        tags={["C++", "render pipelines", "OpenGL", "GLSL", "thesis", "shader"]}
                        gif="rain.webp"
                        altGif="Side-by-side comparison image of a street lamp: On the left, raindrops are visible, creating a glistening effect. On the right, the street lamp is unobstructed, with no rain present."
                        description="My bachelor's thesis focused on a OpenGL/C++ project: I described and implemented a method of augmenting already existing video footage or images with a pre-rendered rainstreak lookup-table, that takes various parameters like camera settings and light source positions into account."
                        link="https://projects.stray.codes/documents/rain.pdf"
                        linkTitle="read thesis"
                    />
                    <PortfolioItem
                        tags={["JavaScript", "WebGL", "indirect rendering", "GLSL"]}
                        gif="fish.webp"
                        altGif="Top-down view of a 2D fishpond. The fishes move collectively, showcasing the principles of a swarm algorithm in action."
                        description="A lightweight, dynamic and responsive fish background for websites powered by JavaScript and WebGL. The color, texture and size of fish can be changed. Each individual fish is a boid. This boids algorightm is based on a paper  by Craig Reynolds, which simulates the flocking behaviour of birds. All the calculations are executed within a shader (Indirect Rendering). The positions, velocity, direction and other data are written and stored in a texture. Shaders in general execute calculations in parallel, which made them most suitable for this task."
                        link="https://gitlab.com/stray.codes/fish"
                        linkTitle="open git"
                    />
                    <PortfolioItem
                        tags={["ReactJS", "Angular", "JavaScript", "Web Design", "HTML", "CSS", "JSX"]}
                        gif="sites.webp"
                        altGif="An animation presenting this and an alternative more colorful website."
                        description="I greatly enjoy web design and have developed multiple websites in my free time including this one. Is this website too simple/gray for you? Feel free to click the button below to experience a more lively and colorful verion of my CV."
                        link="https://cv.stray.codes"
                        linkTitle="click here"
                    />
                    <PortfolioItem
                        tags={["Godot", "Shader", "Python", "Game-Design", "Blender"]}
                        gif="games.webp"
                        altGif="A compilation of multiple video games."
                        description="I participated in multiple Game Jams. All my games are freely accessible on itch.io."
                        link="https://karakuja.itch.io/"
                        linkTitle="open itch.io"
                    />
                </div>
                <div style={{
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-evenly",
                    padding: "20px",
                    maxWidth: "1000px",
                    gap: "10px"
                }}>
                    <a href="https://projects.stray.codes/" style={{fontSize: "30px", padding: "15px", width: "200px", background: "var(--color-blackAlt2)"}}> more projects </a>
                    <a href="https://gitlab.com/stray.codes" target="_blank" rel="noreferrer" style={{fontSize: "30px", padding: "15px", width: "200px", background: "var(--color-blackAlt2)"}}> Gitlab </a>
                </div>
            </div>
        </div>
    );
}

function PortfolioItem({tags, gif, altGif, description, link, linkTitle}) {
    const [descriptionIsVisible, setDescriptionIsVisible] = useState(false);
    return (
        <>
            {descriptionIsVisible && <button
                onClick={() => {
                    setDescriptionIsVisible(!descriptionIsVisible);
                }}
                className="fadeIn"
                style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100vw",
                    height: "100vh",
                    background: "var(--color-black)",
                    zIndex: "3",
                    opacity: "0.985",
                    display: "flex",
                    flexFlow: "column wrap",
                    justifyContent: "center",
                    alignContent: "center",
                    border: 0

                }}>
                <div style={{
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "center",
                    gap: "30px",
                    color: "var(--color-whiteAlt1)",
                    padding: "10px",
                    maxWidth: "800px",
                    height: "max-content",
                    fontSize: "20px",
                }}>
                    <p style={{textAlign: "justify"}}>
                        {description}
                    </p>
                    <a href={link} target="_blank" rel="noreferrer" style={{margin: "auto", fontSize: "30px", padding: "15px", width: "200px", background: "var(--color-blackAlt2)"}}> {linkTitle}</a>
                </div>
            </button>}
            <div
                alt={altGif}
                style={{
                    background: "var(--color-blackAlt2)",
                    width: "340px",
                    height: "250px",
                    margin: "15px",
                    display: "flex",
                    flexFlow: "column wrap",
                    justifyContent: "flex-end",
                    backgroundImage: "url(" + gif + ")",
                    backgroundSize: "cover"
                }}>
                <div style={{
                    flexShrink: "0",
                    padding: "5px",
                    display: "flex",
                    flexFlow: "row wrap",
                    justifyContent: "space-between",
                    alignContent: "flex-end",
                    maxHeight: "5em"
                }}>
                    <div style={{
                        fontSize: "0.8em",
                        display: "flex",
                        flexFlow: "row wrap",
                        alignContent: "flex-end",
                        width: "66%"
                    }}>
                        <Tags tags={tags} />
                    </div>
                    <div style={{display: "flex", flexFlow: "column wrap", justifyContent: "flex-end"}}>
                        <button style={{
                            fontSize: "15px",
                            width: "max-content",
                            height: "max-content",
                            border: "2px solid var(--color-whiteAlt2)",
                            padding: "8px",
                            margin: 0,
                        }}
                            onClick={() => {
                                setDescriptionIsVisible(!descriptionIsVisible);
                            }}
                            className="HeaderButton" >
                            find out more
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}


export default Portfolio;

