import {useInView} from "framer-motion"
import {useEffect, useRef} from "react"
import Tags from "./Tags"

function About({setPagesInView}) {
    const ref = useRef(null)
    const isInView = useInView(ref)
    useEffect(() => {
        setPagesInView(prevPagesInView => {
            let newPagesInView = [...prevPagesInView];
            newPagesInView[1] = isInView;
            return newPagesInView;
        });
    }, [isInView, setPagesInView])
    return (
        <div className="PageContainer">
            <div ref={ref} style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "center",
                alignContent: "center",
                gap: "30px",
                marginBottom: "20px"
            }}>
                <div style={{
                    marginTop: "1px",
                    display: "flex",
                    flexFlow: "column wrap",
                    justifyContent: "center",
                    alignContent: "center",
                    gap: "30px",
                    maxWidth: "300px",
                    minWidth: "200px"
                }}>
                    <Skills title="Programming Languages" tags={["C++", "JavaScript", "Typescript", "OpenGL", "WebGL", "GLSL", "ReactJS", "Angular", "C#"]} />
                    <div style={{
                        background: "var(--color-blackAlt2)",
                        padding: "20px",
                        fontSize: "0.9em",
                            width: "min-content",
                            margin: "auto"
                    }}>
                        <img style={{margin: "auto", maxWidth: "210px"}} src="profile.jpg" alt="Portrait of Karol Czopek"/>
                        <p style={{maxWidth: "210px", margin: "auto", marginTop: "10px"}}>
                            I’m a Software Developer with a
                            passion for computer graphics.
                        </p>
                    </div>
                    <Skills title="Languages" tags={["English", "German", "Polish", "Japanese"]} />
                </div>
                <div style={{
                    display: "flex",
                    flexFlow: "column wrap",
                    justifyContent: "space-between",
                    alignContent: "space-between",
                    gap: "30px",
                    maxWidth: "300px",
                    minWidth: "200px"
                }}>
                    <Skills title="Skills" tags={["Software Development", "System Engineering", "Linear Algebra", "Calculus", "Shader", "Render Pipelines", "OOP", "Encryption", "Indirect Rendering", "Clean Code"]} />

                    <div style={{
                        display: "flex",
                        flexFlow: "row wrap",
                        justifyContent: "space-between",
                        alignContent: "space-between",
                    }}>
                        <img style={{margin: "auto", height: "50px"}} alt="Logo of React JS" src="logos/react.svg" />
                        <img style={{margin: "auto", height: "50px"}} alt="Logo of Java Script" src="logos/js.svg" />
                        <img style={{margin: "auto", height: "50px"}} alt="Logo of Open GL" src="logos/opengl.svg" />
                        <div style={{height: "30px",  width: "100%"}} />
                        <img style={{margin: "auto", height: "50px"}} alt="Logo of C plus plus" src="logos/cpp.svg" />
                        <img style={{margin: "auto", height: "50px"}} alt="Logo of Web Gl" src="logos/webgl.svg" />
                        <img style={{margin: "auto", height: "50px"}} alt="Logo of CSS" src="logos/css.svg" />
                    </div>
                    <Skills title="Software" tags={["Linux", "Office", "command-line", "git", "Godot", "LaTeX", "Bash", "Krita", "Photoshop", "Blender"]} />
                </div>

            </div>
        </div>
    );
}

function Skills({title, tags}) {
    return (
        <div style={{
            background: "var(--color-blackAlt2)",
            width: "auto",
        }}>
            <h3>{title}</h3>
            <div style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "center",
                alignContent: "flex-start",
                width: "auto",
                fontSize: "0.8em",
                margin: "10px",
                marginBottom: "20px",
                marginTop: "-5px",
            }}>
                <Tags tags={tags} />
            </div>
        </div>
    );
}

export default About;

