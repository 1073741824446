import {useInView} from "framer-motion"
import {useEffect, useRef} from "react"
import TimeLineElement from "../TimeLineElement"

function Experience({setPagesInView}) {
    const ref = useRef(null)
    const isInView = useInView(ref)
    useEffect(() => {
        setPagesInView(prevPagesInView => {
            let newPagesInView = [...prevPagesInView];
            newPagesInView[3] = isInView;
            return newPagesInView;
        });
    }, [isInView, setPagesInView])
    return (
        <div className="PageContainer">
            <div ref={ref} style={{display: "flex", flexFlow: "column wrap"}}>
                <TimeLineElement
                    time="03.2022 – today"
                    role="System Engineer"
                    location="Expleo Group"
                    description={<>During my time at Expleo, my primary role centered around the Vehicle Control Unit (VCU) within the Magdeburg Train Project — an integral chip overseeing and supervising diverse train components such as brakes, HVAC units, doors, lighting, and more. As a System Engineer, my key responsibilities encompassed:
                        <ul>
                            <li>Writing software requirements and crafting comprehensive documentation
                                employing Enterprise Architect.</li>
                            <li>Translating these requirements into reality by implementing them through Mitrac Software (proprietary).</li>
                            <li>Devising automated tests utilizing C# and conducting reviews of software requirements and code of various train projects.</li>
                        </ul>
                    </>}
                />
            </div>
        </div>
    );
}

export default Experience;


