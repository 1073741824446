
function Tags({tags}) {
    return (
        <>
            {tags.map((tag, index) => {
                return <div style={{
                    background: "var(--color-white)",
                    color: "var(--color-black)",
                    borderRadius: "5px",
                    padding: "4px",
                    width: "max-content",
                    height: "1.2em",
                    margin: "3px"
                }}
                    key={index}> {tag} </div>;
            })}
        </>
    );
}

export default Tags;
