import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLinkedin, faFacebook, faArtstation, faItchIo, faGitlab, faMastodon} from '@fortawesome/free-brands-svg-icons'
import {useInView} from "framer-motion"
import {useEffect, useRef} from "react"

function Contact({setPagesInView}) {
    const ref = useRef(null)
    const isInView = useInView(ref)
    useEffect(() => {
        setPagesInView(prevPagesInView => {
            let newPagesInView = [...prevPagesInView];
            newPagesInView[5] = isInView;
            return newPagesInView;
        });
    }, [isInView, setPagesInView])
    return (
        <div className="PageContainer">
            <div ref={ref} style={{display: "flex", flexFlow: "column wrap"}}>
                <p style={{padding: "15px"}}>If you are hiring, feel free to reach out through E-Mail or socials listed below. </p>
                <a style={{margin: "20px", fontSize: "40px"}} href={"mailto:hire@stray.codes"}>hire@stray.codes</a>
                <div className="Contacts">
                    <a href={"https://www.linkedin.com/in/karol-czopek-180aa0205/"} rel="noreferrer" target="_blank" className="Socials"><FontAwesomeIcon icon={faLinkedin} /> LinkedIn</a>
                    <a href={"https://karakuja.itch.io/"} rel="noreferrer" target="_blank" className="Socials"><FontAwesomeIcon icon={faItchIo} /> itch.io</a>
                    <a href={"https://www.artstation.com/stray_codes"} rel="noreferrer" target="_blank" className="Socials"><FontAwesomeIcon icon={faArtstation} /> ArtStation</a>
                    <a href={"https://www.facebook.com/profile.php?id=61551518275771"} rel="noreferrer" target="_blank" className="Socials"><FontAwesomeIcon icon={faFacebook} /> Facebook</a>
                    <a href={"https://gitlab.com/stray.codes"} rel="noreferrer" target="_blank" className="Socials"><FontAwesomeIcon icon={faGitlab} /> Gitlab</a>
                    <a href={"https://mastodon.social/@stray_codes"} rel="noreferrer" target="_blank" className="Socials"><FontAwesomeIcon icon={faMastodon} /> Mastodon</a>
                </div>
            </div>
        </div>
    );
}

export default Contact;

